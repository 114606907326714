import { fetchRatesForBedbankOffersById } from 'actions/BedbankOfferActions'
import { excludeNullOrUndefined } from 'checkout/utils'
import { fetchCalendarsByOccupancy } from 'actions/CalendarActions'
import { fetchAvailableRatesForOffer, fetchOfferById } from 'actions/OfferActions'
import { checkoutAccommodationOfferView, getAccommodationItems } from 'checkout/selectors/view/accommodation'
import { RESERVATION_TYPE_INSTANT_BOOKING } from 'constants/reservation'
import { useAppDispatch, useAppSelector, shallowEqual } from 'hooks/reduxHooks'
import { useEffect } from 'react'
import { getBundledFlightItems } from 'checkout/selectors/view/flights'
import { isLEHotel } from 'lib/offer/offerTypes'
import { getItemOfferId } from 'lib/checkout/checkoutUtils'
import { groupBy, nonNullable } from 'lib/array/arrayUtils'

function useCheckoutOfferData(stateOverride?: App.State) {
  const dispatch = useAppDispatch()

  const {
    regionCode,
    currencyCode,
    existingOrder,
    accommodationItems,
    bundledFlightItems,
    offerViews,
  } = useAppSelector((currentState) => {
    const state = stateOverride ?? currentState
    return {
      regionCode: state.checkout.cart.regionCode,
      currencyCode: state.checkout.cart.currencyCode,
      existingOrder: state.checkout.cart.existingOrder,
      accommodationItems: getAccommodationItems(state),
      bundledFlightItems: getBundledFlightItems(state),
      offerViews: checkoutAccommodationOfferView(state),
    } }, shallowEqual)

  const flightOrigin = bundledFlightItems[0]?.originAirportCode

  useEffect(() => {
    const offerIds = nonNullable([
      ...accommodationItems.map(getItemOfferId),
      ...(existingOrder?.items ?? []).map(item => item.offerId),
    ])
    // Fetching one by one as currently sold out offers are not returned via the multi-fetch endpoint
    // This can result in checkout not loading for post-purchase actions such as Pay Balance
    offerIds.forEach(offerId => {
      dispatch(fetchOfferById(offerId))
    })
  }, [dispatch, accommodationItems, existingOrder])

  // Get LE hotel calendar data
  useEffect(() => {
    offerViews.data.forEach((view) => {
      const offer = view.offer
      if (
        view.reservationType === RESERVATION_TYPE_INSTANT_BOOKING &&
        isLEHotel(offer)
      ) {
        const { occupancy: occupants, startDate: checkIn, endDate: checkOut } = view
        const uniqueKeys = new Set(view.itemViews.map((itemView: App.Checkout.AccommodationItemView) => itemView.uniqueKey))

        dispatch(fetchCalendarsByOccupancy(offer.id, {
          occupants,
          packages: offer.packages.filter(pkg => uniqueKeys.has(pkg.uniqueKey)),
          regionCode,
          flightOrigin,
        }))

        if (offer.type === 'tactical_ao_hotel' && checkIn && checkOut) {
          occupants.forEach(occupant => {
            dispatch(fetchAvailableRatesForOffer(
              offer, {
                occupants: [occupant],
                checkIn,
                checkOut,
                currencyCode,
                flightOrigin,
              },
            ))
          })
        }
      } else if (view.offerType === 'bedbank_hotel') {
        const { offerId, startDate: checkIn, endDate: checkOut } = view
        if (checkIn && checkOut) {
          const groupedItems = groupBy<string, App.Checkout.BedbankAccommodationItemView>(view.itemViews, (v) => v.item.sessionId ?? 'none')

          for (const itemViews of Array.from(groupedItems.values())) {
            dispatch(fetchRatesForBedbankOffersById([offerId], itemViews.map((v) => v.item.occupancy).filter(excludeNullOrUndefined), checkIn, checkOut))
          }
        }
      }
    })
  }, [currencyCode, dispatch, flightOrigin, offerViews, regionCode])
}

export default useCheckoutOfferData
